<template>
  <div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
    <div class="text-900 font-bold text-5xl mb-4 text-center">
      Value 4 Value
    </div>
    <div class="text-700 text-4xl mb-8 line-height-3">
      <strong>BITBRAIN</strong> is currently offered for free, driven by a
      passion for R&D and lifelong learning. If you've found value in our site
      and would like to give back, we welcome contributions of any amount to
      support our infrastructure and future development. Your support is greatly
      appreciated. Thank you.
    </div>

    <div class="grid">
      <div class="col-12 lg:col-6">
        <div class="p-3 h-full">
          <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
          >
            <div class="text-900 font-medium text-4xl mb-2">
              <i class="pi pi-qrcode text-4xl text-blue-500"></i>
              Bitcoin
            </div>

            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />

            <div class="qr-wrapper m-8">
              <img class="qr" src="@/assets/btc-qr.png" />
            </div>

            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />

            <div class="funding-footer">
              <div class="text-600 text-2xl">
                bc1q9yxcjd6kmyk8x8e47axg06ax47u0kldmqp0lfu
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="p-3 h-full">
          <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
          >
            <div class="text-900 font-medium text-4xl mb-2">
              <i class="pi pi-money-bill text-4xl text-green-500"></i>
              Paypal
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="qr-wrapper">
              <img class="qr" src="@/assets/paypal-qr.png" />
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="funding-footer">
              <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_top"
              >
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="QS7D7N596JASJ"
                />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/WEBSCR-640-20110429-1/en_US/i/btn/btn_donate_SM.gif"
                  border="0"
                  style="height: 26px"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.qr {
  width: 148px;
  height: 148px;
}
.qr-wrapper {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-600 {
  overflow-wrap: break-word;
}

.funding-footer {
  text-align: center;
}
</style>
