<template>
  <div v-if="data.content" class="bb-intro">
    <div class="bb-intro-splash">
      <div class="bb-intro-text">
        <div class="bb-column">
          <span>
            <h2>BITBRAIN</h2>
          </span>
          <span>
            <h4>
              Learn by
              <transition-group name="fade">
                <div class="learn-text" :key="data.current_text">
                  {{ data.learn_text[data.current_text] }}.
                </div>
              </transition-group>
            </h4>
          </span>
        </div>

        <div class="bb-column bb-column-img">
          <img src="@/assets/logo-w.svg" />
        </div>
      </div>
    </div>

    <div id="features">
      <intro-features />
    </div>

    <div class="bb-intro-mission">
      <div class="bb-intro-mission-body p-8">
        <Card class="mission-card">
          <template #content>
            <div class="text-5xl mb-4 font-bold text-center">Mission</div>
            <div class="text-200 text-4xl mb-3 line-height-3">
              BITBRAIN is a dynamic learning platform that empowers users to
              explore their creativity, solve problems, and develop practical
              skills through hands-on experimentation, prototyping, and
              sandboxing. Our mission is to ignite a passion for lifelong
              learning and inspire innovative thinking by fostering a "learn by
              doing" mentality.
            </div>
          </template>
        </Card>
      </div>
    </div>

    <div id="funding">
      <intro-funding />
    </div>

    <div class="block-content">
      <div class="">
        <div
          class="
            text-center
            surface-section
            px-4
            md:px-6
            lg:px-8
            footer-section
          "
        >
          <div
            class="
              py-6
              flex flex-column
              sm:align-items-center
              justify-content-between
            "
          >
            <div>
              <p class="text-600 line-height-3 mt-0 mb-4">
                © 2022 Bitbrain - Proudly made in Kentucky. Inquiries:
                <a href="mailto:me@reppard.com">me@reppard.com</a>
              </p>
            </div>
            <div class="mt-3 sm:mt-0">
              <a
                href="https://github.com/bitbrainlife"
                class="cursor-pointer text-700"
              >
                <i class="text-6xl pi pi-github"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <!---->
    </div>
  </div>
</template>

<script setup>
import IntroFeatures from "@/components/IntroFeatures.vue";
import IntroFunding from "@/components/IntroFunding.vue";
import { onMounted, reactive } from "vue";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_BASEURL;

const data = reactive({
  content: null,
  current: 0,
  direction: 1,

  learn_text: ["doing", "building", "creating", "teaching"],
  current_text: 0,
});

onMounted(async () => {
  var resp = await axios.get(BASE_URL + "/landing");
  data.content = resp.data;
  console.log(data.content);

  setInterval(() => {
    if (data.current_text < data.learn_text.length - 1) {
      data.current_text += 1;
    } else {
      data.current_text = 0;
    }
  }, 3000);
});
</script>

<style scoped>
p,
h3 {
  padding: 1em 0;
  margin: 0;
}

h4 {
  font-size: 1.5em;
  font-weight: normal;
}

h3 {
  border-bottom: 1px solid var(--bb-current-line);
}

.bb-intro {
  background: var(--surface-ground);
}

.bb-intro-mission {
  background: #2c3947;
}

.bb-intro-mission-title {
  width: 100%;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  margin: 32px auto;
}

.bb-intro-mission-body {
  padding: 2em;
  margin: 0 auto;
  font-size: 1em;
}

.bb-funding {
  font-size: 1em;
  padding: 3em;
  background: var(--orange-50);
}

.bb-funding-body {
  max-width: 50%;
  margin: 0 auto;
  font-size: 1em;
}

.bb-funding-header {
  padding-bottom: 1em;
}

.bb-funding-text {
  padding-top: 1em;
  margin-bottom: 3em;
}

.bb-funding-method {
  margin: 1em 0;
  text-align: center;
  padding: 1em;
  overflow-wrap: anywhere;
}

.bb-funding-method h3 {
  padding: 0.5em;
  margin-bottom: 0.5em;
}

.bb-offerings {
  padding: 4em;
}

.bb-offering-body {
  display: flex;
  padding: 2em;
  margin: 0 auto;
  font-size: 1em;
}

.bb-intro-footer {
  font-size: 0.85em;
  max-width: 100%;
  padding: 24px;
  text-align: center;
  background: var(--yellow-100);
}

.bb-intro-splash {
  padding: 24px;
  display: flex;
  position: relative;
  overflow: hidden;
  text-align: center;
  height: 100vh;
  box-sizing: border-box;
  box-shadow: 0px -4px 8px 6px var(--bb-black);
  background: linear-gradient(45deg, #2bcfd9, transparent);
  background: linear-gradient(45deg, #2bcfd9, #048e97);
  background: linear-gradient(45deg, var(--cyan-500), var(--cyan-600));
}

.bb-intro-splash img {
  position: relative;
  filter: brightness(0.9) opacity(0.2);
  max-height: 500px;
}

.bb-intro-text {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.bb-intro-text h2 {
  font-weight: bold;
  font-size: 10vw;
  margin: 0;
  color: #243252;
}

.bb-intro-text h4 {
  font-size: 2vw;
  font-weight: bold;
  padding: 0;
  margin: 0;
  color: #fff;
}

.bb-column {
  align-self: center;
  box-sizing: border-box;
  /* margin: 2.5rem; */
  position: relative;
  color: var(--surface-300);
}

.bb-column.bb-column-img {
  text-align: right;
}

.bb-intro-courses {
  margin-top: 2.5em;
  border: none;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/* SLIDES CLASSES */

.learn-text {
  display: inline-block;
  margin-left: 0.5em;
  position: absolute;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.grid-splash {
  padding: 0;
  width: 100%;
}

.clipped {
  background: var(--cyan-800);
  background: linear-gradient(45deg, var(--cyan-800), transparent);
  clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%);
}

.splash {
  background: var(--bb-background);
  color: var(--surface-200);
}

@media screen and (max-width: 640px) {
  .bb-intro {
    padding: 0em;
  }

  .bb-intro-mission-body {
    max-width: 100%;
  }

  .bb-intro-splash img {
    width: unset;
    height: 100%;
  }

  .bb-intro-text {
    display: grid;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: start;
  }

  .bb-intro-mission {
    padding: 24px 0;
  }

  .bb-funding,
  .bb-funding-body {
    max-width: 100%;
    width: 100%;
  }

  .clipped {
    background: var(--cyan-800);
    background: linear-gradient(45deg, var(--cyan-800), transparent);
    clip-path: unset;
  }
  .bb-intro-text h2 {
    font-size: 3em;
  }

  .bb-intro-text h4 {
    font-size: 2em;
  }
}

.surface-section {
  background-color: unset !important;
}

.mission-card {
  color: var(--yellow-50);
  background: none;
  border: none;
  box-shadow: none;
}

.footer-section {
  box-shadow: 0px 4px 9px 0px #a2aebd;
  background-color: white !important;
}

.footer-logo {
  background: var(--teal-800);
  border-radius: 24px;
  overflow: hidden;
  height: 120px;
  width: 120px;
  padding: 1em;
}
</style>
