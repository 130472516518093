import { createRouter, createWebHistory } from "vue-router";
import IntroView from "@/components/IntroView";
import BrainBitsList from "@/components/BrainBitsList";
import MainView from "@/components/MainView";
import MarkdownView from "@/components/MarkdownView";
import LessonView from "@/components/LessonView";
import CoursesView from "@/components/CoursesView";
import CourseOverview from "@/components/CourseOverview";
import CodeboxView from "@/components/CodeboxView";
import SandboxView from "@/components/SandboxView";
import SockboxView from "@/components/SockboxView";

const routes = [
  {
    path: "/",
    name: "home",
    component: IntroView,
  },
  {
    path: "/courses",
    name: "courses",
    component: CoursesView,
  },
  {
    path: "/lesson",
    name: "lessons",
    component: MainView,
  },
  {
    path: "/blog",
    name: "blog",
    component: MainView,
  },
  {
    path: "/sockbox",
    name: "sockbox",
    component: SockboxView,
  },
  {
    path: "/sockbox/:id",
    component: SockboxView,
  },
  {
    path: "/codebox",
    name: "codebox",
    component: CodeboxView,
  },
  {
    path: "/sandbox",
    name: "sandbox",
    component: SandboxView,
  },
  {
    path: "/sandbox/:slug",
    component: SandboxView,
  },
  {
    path: "/courses/:id",
    component: CourseOverview,
  },
  {
    path: "/lessons/:course/:number",
    name: "lesson",
    component: LessonView,
  },
  {
    path: "/brainbits",
    name: "brainbits",
    component: BrainBitsList,
  },
  {
    path: "/markdown",
    name: "markdown",
    component: MarkdownView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

//router.beforeEach(async (to, from, next) => {
//  await auth.refreshToken()

//  console.log(auth.state.role)

//  if (auth.is_authenticated.value && to.name == "login")
//    next({ name: "home" })
//  else if (!auth.is_authenticated.value && to.name !== "login")
//    next({ name: "login" })
//  else next()
//})

export default router;
