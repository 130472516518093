<template>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div class="mb-3 mt-6 font-bold text-5xl text-center">
      <span class="text-900">Bitbrain </span>
      <span class="text-blue-600">Offerings</span>
    </div>

    <div class="text-700 text-3xl mb-8 text-center">
      A place to build, learn, play.
    </div>

    <div class="grid">
      <div
        v-for="feature in features"
        :key="feature"
        class="col-12 md:col-4 mb-4 px-5 text-center"
      >
        <span
          class="p-4 shadow-1 mb-4 inline-block surface-card"
          style="border-radius: 10px"
        >
          <router-link :to="feature.link || '/'">
            <i
              class="pi text-8xl text-blue-500 text-center"
              :class="feature.icon"
            ></i>
          </router-link>
        </span>
        <div class="text-900 mb-6 font-bold text-3xl text-center">
          {{ feature.name }}
        </div>
        <span class="text-700 text-2xl line-height-3 text-right">
          {{ feature.description }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
const features = [
  {
    name: "Sandbox",
    icon: "pi-box",
    description:
      "If you are familiar with sites like jsfiddle or codepen, " +
      "you will feel right at home in the sandbox. Prototype your HTML, CSS, " +
      " and Javascript right in the browser.\n" +
      "Support for JS frameworks will be added in the future.",
    link: "/sandbox",
  },
  {
    name: "Codebox",
    icon: "pi-code",
    description:
      "Codebox is very similar to the Sandbox though it has only one code panel " +
      "dedicated to the selected language.\n" +
      "Golang, Ruby, and Python are currently supported. More to come!",
    link: "/codebox",
  },
  {
    name: "Sockbox",
    icon: "pi-desktop",
    description:
      "Connect to a fully functional Alpine Linux shell session right in your " +
      "browser!\n" +
      "A fresh container is spun up and served over WebSocket.",
    link: "/sockbox",
  },
  {
    name: "Markdown Editor",
    icon: "pi-pencil",
    description:
      "A browser based Markdown editor with live preview, save to local storage, and VIM mode!",
    link: "/markdown",
  },
  {
    name: "Courses",
    icon: "pi-file",
    description: "COMING SOON!",
  },
];
</script>
