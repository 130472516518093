<template>
  <div
    @mousemove="handleGutter"
    @mouseup="
      data.resizingV = false;
      data.resizingHL = false;
      data.resizingHR = false;
    "
    ref="sandbox"
    :style="computedStyle"
    class="sandbox"
  >
    <div :style="{ width: data.leftViewWidth }" class="sandbox-left">
      <div :style="leftTopStyle" class="cell">
        <div class="ace-label">
          <button
            class="bb-fullscreen"
            @click="data.htmlFullscreen = !data.htmlFullscreen"
          ></button>

          <select
            value=""
            @change="
              (e) => {
                setTheme('html', e.target.value);
              }
            "
          >
            <option disabled value="">select theme</option>
            <option :key="v" v-for="(v, k) in themes" :value="v">
              {{ `${v} (${k})` }}
            </option>
          </select>

          <div>HTML</div>
        </div>
        <v-ace-editor
          class="ace-html"
          v-model:value="data.html"
          @init="
            (e) => {
              setEditor('html', e);
              editorInit(e);
            }
          "
          @input="handleUpdate"
          lang="html"
          :options="{
            fontSize: 14,
            mode: 'ace/mode/html',
            displayIndentGuides: true,
            highlightGutterLine: false,
            showPrintMargin: false,
            useSoftTabs: true,
            useWorker: true,
          }"
          style="height: 100%"
        />
      </div>

      <div @mousedown="data.resizingHL = true" class="h-gutter"></div>

      <div :style="leftBottomStyle" class="cell">
        <div class="ace-label">
          <button
            class="bb-fullscreen"
            @click="data.jsFullscreen = !data.jsFullscreen"
          ></button>

          <select
            value=""
            @change="
              (e) => {
                setTheme('js', e.target.value);
              }
            "
          >
            <option disabled value="">select theme</option>
            <option :key="v" v-for="(v, k) in themes" :value="v">
              {{ `${v} (${k})` }}
            </option>
          </select>

          <div>JavaScript</div>
        </div>
        <v-ace-editor
          class="ace-js"
          v-model:value="data.js"
          @init="
            (e) => {
              setEditor('js', e);
              editorInit(e);
            }
          "
          @input="handleUpdate"
          lang="javascript"
          :options="{
            fontSize: 14,
            mode: 'ace/mode/javascript',
            displayIndentGuides: true,
            highlightGutterLine: false,
            showPrintMargin: false,
            useSoftTabs: true,
            useWorker: true,
          }"
          style="height: 100%"
        />
      </div>
    </div>

    <div @mousedown="data.resizingV = true" class="v-gutter"></div>

    <div :style="{ width: data.rightViewWidth }" class="sandbox-right">
      <div :style="rightTopStyle" class="cell">
        <div class="ace-label">
          <button
            class="bb-fullscreen"
            @click="data.cssFullscreen = !data.cssFullscreen"
          ></button>

          <select
            value=""
            @change="
              (e) => {
                setTheme('css', e.target.value);
              }
            "
          >
            <option disabled value="">select theme</option>
            <option :key="v" v-for="(v, k) in themes" :value="v">
              {{ `${v} (${k})` }}
            </option>
          </select>

          <div>CSS</div>
        </div>
        <v-ace-editor
          class="ace-css"
          v-model:value="data.css"
          @init="
            (e) => {
              setEditor('css', e);
              editorInit(e);
            }
          "
          @input="handleUpdate"
          lang="css"
          :options="{
            fontSize: 14,
            mode: 'ace/mode/css',
            displayIndentGuides: true,
            highlightGutterLine: false,
            showPrintMargin: false,
            useSoftTabs: true,
            useWorker: true,
          }"
          style="height: 100%"
        />
      </div>

      <div @mousedown="data.resizingHR = true" class="h-gutter"></div>

      <div :style="rightBottomStyle" class="cell cell-result">
        <div class="ace-label">
          <button
            class="bb-fullscreen"
            @click="data.resultFullscreen = !data.resultFullscreen"
          ></button>

          Result
        </div>
        <div class="result-frame">
          <iframe
            :style="{ background: data.srcdoc ? 'white' : 'none' }"
            allowfullscreen
            scrolling="yes"
            :srcdoc="data.srcdoc"
            id="frame"
          >
          </iframe>
        </div>
        <div class="cell-result-bottom">
          <Button
            class="p-button-sm"
            :class="{ disabled: !data.autorun }"
            id="refresh"
            @click="data.autorun = !data.autorun"
          >
            {{ data.autorun ? "auto-on" : "auto-off" }}
          </Button>

          <Button
            class="p-button-sm"
            :disabled="data.submitting"
            id="run"
            @click="runContent"
          >
            run
          </Button>

          <Button
            class="p-button-sm"
            :disabled="data.submitting"
            v-if="$route.path.match('/sandbox')"
            id="save"
            @click="handleSave"
          >
            save
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import router from "@/router/index";
import { VAceEditor } from "vue3-ace-editor";
import { ref, onMounted, reactive, computed, defineProps } from "vue";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-ambiance";
import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/theme-cobalt";
import "ace-builds/src-noconflict/theme-crimson_editor";
import "ace-builds/src-noconflict/theme-dawn";
import "ace-builds/src-noconflict/theme-dreamweaver";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/theme-eclipse";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-gob";
import "ace-builds/src-noconflict/theme-gruvbox";
import "ace-builds/src-noconflict/theme-nord_dark";
import "ace-builds/src-noconflict/theme-pastel_on_dark";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/theme-terminal";

const themes = {
  default: "dracula",
  light: "dawn",
  dark: "chaos",
  ambiance: "ambiance",
  cobalt: "cobalt",
  crimson: "crimson_editor",
  dreamweaver: "dreamweaver",
  eclipse: "eclipse",
  github: "github",
  gob: "gob",
  gruvbox: "gruvbox",
  nord_dark: "nord_dark",
  pastel_on_dark: "pastel_on_dark",
  solarized_dark: "solarized_dark",
  solarized_light: "solarized_light",
  terminal: "terminal",
};
const sandbox = ref(null);
const BASE_URL = process.env.VUE_APP_BASEURL;

const setEditor = (name, e) => {
  data.editors[name] = e;
};

const setTheme = (name, theme) => {
  data.editors[name].setTheme("ace/theme/" + theme);
};

const editorInit = (e) => {
  e.setTheme("ace/theme/" + themes.default);
};

onMounted(async () => {
  let slug = router.currentRoute.value.params.slug;
  let currentValue = JSON.parse(localStorage.getItem("bb_sb_current_value"));

  if (slug) {
    data.submitting = true;

    var resp = await axios.get(BASE_URL + "/brainbits?slug=" + slug);
    var bit = resp.data.data[0];

    data.html = bit.attributes.html;
    data.css = bit.attributes.css;
    data.js = bit.attributes.js;
    data.slug = bit.attributes.slug;
    data.submitting = false;
  } else if (currentValue) {
    data.html = currentValue.html;
    data.css = currentValue.css;
    data.js = currentValue.js;
  }

  setInterval(() => {
    if (data.autorun) runContent();
  }, 30000);
});

const handleSave = async (e) => {
  e.preventDefault();

  data.submitting = true;
  var bit = await axios.post(BASE_URL + "/brainbits", {
    data: {
      slug: data.slug,
      html: data.html,
      css: data.css,
      js: data.js,
    },
  });

  console.log(bit);
  router.push("/sandbox/" + bit.data.data.attributes.slug);

  data.submitting = false;
};

const handleUpdate = () => {
  let value = { html: data.html, css: data.css, js: data.js };

  localStorage.setItem("bb_sb_current_value", JSON.stringify(value));
};

const props = defineProps({
  width: {
    type: String,
    default: "50vw",
  },

  disableScroll: {
    type: Boolean,
    default: false,
  },
});

const data = reactive({
  srcdoc: null,
  resultFullscreen: false,
  jsFullscreen: false,
  cssFullscreen: false,
  htmlFullscreen: false,
  autorun: true,
  html: "",
  js: "",
  css: "",
  slug: null,
  editors: {
    html: null,
    css: null,
    js: null,
  },
  submitting: false,
  resizingV: false,
  resizingHL: false,
  resizingHR: false,
  leftViewWidth: "calc(50% - 0.5px)",
  rightViewWidth: "calc(50% - 0.5px)",
  leftTopHeight: "calc(50% - 0.5px)",
  leftBottomHeight: "calc(50% - 0.5px)",
  rightTopHeight: "calc(50% - 0.5px)",
  rightBottomHeight: "calc(50% - 0.5px)",
});

const handleGutter = (e) => {
  e.preventDefault();

  if (data.resizingV) {
    let delta = window.innerWidth - sandbox.value.offsetWidth;
    let percent = ((e.clientX - delta) / sandbox.value.offsetWidth) * 100;
    data.leftViewWidth = `calc(${percent}% - 0.5px)`;
    data.rightViewWidth = `calc(${100 - percent}% - 0.5px)`;
  }

  if (data.resizingHL) {
    let percent = ((e.clientY - 56) / sandbox.value.offsetHeight) * 100;
    data.leftTopHeight = `calc(${percent}% - 0.5px)`;
    data.leftBottomHeight = `calc(${100 - percent}% - 0.5px)`;
  }

  if (data.resizingHR) {
    let percent = ((e.clientY - 56) / sandbox.value.offsetHeight) * 100;
    data.rightTopHeight = `calc(${percent}% - 0.5px)`;
    data.rightBottomHeight = `calc(${100 - percent}% - 0.5px)`;
  }
};

const getContent = () => {
  /* eslint-disable */
  return `
  <html>
    <body>
      ${data.html}
    </body>
    <script>
      ${data.js}
    <\/script>
    <style>
      ${data.css}
    </style>
  </html>`;
};

const runContent = () => {
  data.srcdoc = null;

  setTimeout(() => {
    if (data.html) {
      data.srcdoc = getContent();
    }
  }, 300);
};

const computedStyle = computed(() => {
  return { width: props.width, userSelect: props.disableScroll };
});

const leftTopStyle = computed(() => {
  return {
    height: data.htmlFullscreen ? "100%" : data.leftTopHeight,
    width: data.htmlFullscreen ? "100%" : "unset",
    top: data.htmlFullscreen ? 0 : "unset",
    left: data.htmlFullscreen ? 0 : "unset",
    zIndex: data.htmlFullscreen ? 100 : "unset",
    position: data.htmlFullscreen ? "absolute" : "relative",
  };
});

const rightTopStyle = computed(() => {
  return {
    height: data.cssFullscreen ? "100%" : data.rightTopHeight,
    width: data.cssFullscreen ? "100%" : "unset",
    top: data.cssFullscreen ? 0 : "unset",
    left: data.cssFullscreen ? 0 : "unset",
    zIndex: data.cssFullscreen ? 100 : "unset",
    position: data.cssFullscreen ? "absolute" : "relative",
  };
});

const leftBottomStyle = computed(() => {
  return {
    height: data.jsFullscreen ? "100%" : data.leftBottomHeight,
    width: data.jsFullscreen ? "100%" : "unset",
    top: data.jsFullscreen ? 0 : "unset",
    left: data.jsFullscreen ? 0 : "unset",
    zIndex: data.jsFullscreen ? 100 : "unset",
    position: data.jsFullscreen ? "absolute" : "relative",
  };
});

const rightBottomStyle = computed(() => {
  return {
    height: data.resultFullscreen ? "100%" : data.rightBottomHeight,
    width: data.resultFullscreen ? "100%" : "unset",
    top: data.resultFullscreen ? 0 : "unset",
    left: data.resultFullscreen ? 0 : "unset",
    zIndex: data.resultFullscreen ? 100 : "unset",
    position: data.resultFullscreen ? "absolute" : "relative",
  };
});
</script>

<style scoped>
.sandbox {
  min-width: 120px;
  box-sizing: border-box;
  background: var(--bb-background);
  color: var(--bb-foreground);
  margin-top: 65px;
}

.sandbox-left {
  height: 100%;
  float: left;
}

.sandbox-right {
  height: 100%;
  float: left;
}

.v-gutter {
  width: 1px;
  height: 100%;
  float: left;
  position: relative;
  cursor: ew-resize;
  background: var(--bb-comment);
}

.h-gutter {
  height: 1px;
  position: relative;
  cursor: ns-resize;
  background: var(--bb-comment);
}

.v-gutter:after {
  position: absolute;
  content: "";
  display: block;
  height: 100%;
  width: 8px;
  left: -3px;
  z-index: 10;
}

.h-gutter:after {
  position: absolute;
  content: "";
  display: block;
  height: 8px;
  width: 100%;
  left: -3px;
  z-index: 10;
}

.cell {
  overflow: hidden;
  height: calc(50% - 0.5px);
  box-shadow: 2px 2px 2px 0px var(--bb-background);
  display: grid;
  grid-template-rows: min-content 1fr;
}

.cell-bottom {
  display: grid;
  grid-template-rows: 1fr auto;
}

.cell-result {
  display: grid;
  grid-template-rows: min-content 1fr auto;
}

.result-frame {
  position: relative;
  box-sizing: border-box;
  background: var(--bb-background);
}

label {
  padding-left: 8px;
  padding-top: 8px;
  font-weight: bold;
  font-size: 12px;
  /* color: var(--bb-purple);*/
}

iframe {
  height: 100%;
  width: 100%;
  margin: 0;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
}

.ace_editor {
  height: calc(100% - 24px);
}

.ace-label {
  position: relative;
  width: 100%;
  line-height: 16px;
  font-size: 12px;
  z-index: 2;
  padding: 4px;
  display: flex;
  background: var(--bb-background);
  box-sizing: border-box;
  /*color: var(--bb-purple);*/
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.bb-fullscreen {
  padding: 0;
  border-radius: 0;
  background: none;
  float: left;
  position: relative;
  width: 14px;
  height: 14px;
  border: 2px solid white;
}

.bb-fullscreen:after {
  content: "";
  position: absolute;
  background: var(--bb-background);
  height: 6px;
  top: 2px;
  left: -2px;
  width: 14px;
}

.bb-fullscreen:before {
  content: "";
  position: absolute;
  background: var(--bb-background);
  width: 6px;
  top: -2px;
  left: 2px;
  height: 14px;
}

.bottom {
  text-align: right;
}

.cell-result-bottom {
  box-sizing: border-box;
  text-align: right;
  padding: 4px;
  background: var(--bb-background);
}

.cell-result-bottom button {
  justify-self: end;
  margin: 0 4px;
}

button {
  text-transform: capitalize;
}

button.disabled {
  border: 1px solid grey;
  color: grey;
}

.toggle-theme {
  color: white;
  border: none;
}

.toggle-theme:active {
  background: none;
  border: 1px solid white;
}

select,
select:active,
select:focus,
select:hover {
  outline: none;
  border: 1px solid white;
  border-radius: 4px;
  background: none;
  color: white;
}

select option {
  background: var(--bb-background);
  color: white;
}

select option:active,
select option:focus,
select option:hover {
  border: 1px solid var(--bb-green);
  box-shadow: 0 0 3pt 2pt #719ece;
}

.sandbox ::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

.sandbox ::-webkit-scrollbar-thumb {
  background: rgb(68 71 90 / 80%);
  border-radius: 0.5em;
}

.sandbox ::-webkit-scrollbar-corner,
.sandbox ::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(68 71 90 / 40%);
  border-radius: 0.5em;
}
</style>
